import React from "react";

const NotFoundPage = () => (
    <div className="Application is-fullheight">
    <section className="not-found">
        <div>
            <h1>NOT FOUND</h1>
            <p>Tut uns leid, wir haben leider die Seite nicht gefunden...</p>
        </div>
    </section>
  </div>
)

export default NotFoundPage;
